.header-view2 {
    .header {
        margin: 10px;
        width: 100%;
        height: 60px;
        border-radius: 30px;
        background: rgba(0, 0, 0, 0.7);
        padding: 0 20px;
    }

    .menuList {
        margin-left: 140px;

        .menuItem {
            margin-left: 30px;
            cursor: pointer;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .balanceList {
        .balanceItem {
            padding: 0 10px;
            height: 30px;
            background: rgba(255, 255, 255, 0.2);
            cursor: pointer;
        }
    }

    .connectBtn {
        width: 140px;
        height: 36px;
        border: 1px solid rgba(74, 178, 238, 0.3);
        margin-left: 70px;
        cursor: pointer;
        &:hover {
            color: #FFFFFF;
            background: linear-gradient(107deg, #4AB2EE 8%, #4AB2EE 8%, #864AEE 93%, #864AEE 93%);
            border: 1px solid rgba(74, 178, 238, 0.3);
        }
    }
}

.menuBox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(209deg, #001413 0%, #001413 0%, #4574F6 38%, #6487D9 64%, #B829F1 99%, #B829F1 99%);

    .logo {
        height: 32px;
    }

    .close {
        margin-right: 18px;
    }

    .navList {
        margin-top: 40px;
        padding: 0 10px;

        .navItemContent {
            width: 100%;
            height: 48px;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    .balance {
        padding: 0 10px;
        .balanceItemBox {
            flex: 1;
            padding: 15px 10px;        
            background: rgba(0, 0, 0, 0.5);    
            .balanceItem {
                padding-bottom: 14px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }
        }
    }
}

.MuiLink-root:hover {
    color: #FFFFFF!important;
}
.MuiLink-root.active {
    text-decoration: none!important;
}

@media only screen and (max-width: 1080px) {
    .header-view2 {
        .header {
            margin: 0;
            background: transparent;

            .connectBtn {
                width: 100px;
                margin-left: 0;
            }
        }
    }
}