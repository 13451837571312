* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Pricedown-Bl";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

.font-arial {
  font-family: "arial" !important;
}

.font-Montserrat-VariableFont {
  font-family: "Montserrat-VariableFont";
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Pricedown-Bl";
  transition: all ease 0.33ms;
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20PX;
}

.font-24 {
  font-size: 24PX;
}

.font-36 {
  font-size: 36px;
}

.font-weight-b {
  font-weight: bold;
}

.font-weight-9 {
  font-weight: 900;
}


.color1 {
  color: #00FFEE;
}

.color2 {
  color: #000000;
}

.color3 {
  color: #FFB300;
}

.color4 {
  color: #00C8FF;
}

.color5 {
  color: #643C00;
}

.color6 {
  color: #00FF66;
}

.color7 {
  color: #FF6A00;
}

.color8 {
  color: #D9D9D9;
}

.color9 {
  color: #96500B;
}

.color10 {
  color: #025C80;
}

.color11 {
  color: #D8D8D8;
}

.color12 {
  color: #FFFFFF;
}

.color13 {
  color: rgba(255, 255, 255, 0.5);
}

.color14 {
  color: #4AB2EE;
}

.color15 {
  color: #DF11BD;
}

.text-center {
  text-align: center;
}

.border-radius-4 {
  border-radius: 4px;
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 1080px) {
  body {
    letter-spacing: 1.5px;
  }
}

@media only screen and (max-width: 1080px) {
  .container {
    width: 375px;
  }
}